<template>
  <div>
    <template
      v-if="
        (institution && !institution.kl_assessment) ||
        (user && user.groups.includes(7))
      "
    >
      <Page404></Page404>
    </template>
    <template v-else>
      <ManageEvaluations :allows_crud="allows_crud"></ManageEvaluations>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "ManageEvaluationsView",
  components: {
    ManageEvaluations: () => import("./ManageEvaluations.vue"),
    Page404: () => import("@/components/public/Page404"),
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    allows_crud() {
      const has_permission = this.$hasPermissions(
        "evaluations2.add_evaluation"
      );
      if (has_permission) return has_permission;
      else return false;
    },
    ...mapGetters({
      institution: "getInstitution",
      user: "getUser",
    }),
  },
  methods: {},
  created() {
    this.$store.dispatch(names.FETCH_NEW_TEST_TYPES);
  },
};
</script>

<style scoped>
</style>